import React, { useEffect, useState } from "react";
import axios from "axios";
import { Carousel } from "react-bootstrap";

const API_BASE = "https://upload.villemajava.com";

export default function WeddingPhotoCarouselBootstrap() {
  const [photos, setPhotos] = useState([]);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const res = await axios.get(`${API_BASE}/photos`);
        setPhotos(res.data);
      } catch (err) {
        console.error("Kuvien haku epäonnistui:", err);
      }
    };
    fetchPhotos();
  }, []);

  const handleUpload = async () => {
    if (!file) return;
    setUploading(true);
    const formData = new FormData();
    formData.append("media", file);

    try {
      const res = await axios.post(`${API_BASE}/upload`, formData, {
        timeout: 30000, // 30 sekuntia
      });
      const uploadedUrl = res.data.url;
      console.log("Tallennettu tiedoston URL:", uploadedUrl);
      setPhotos((prev) => [uploadedUrl, ...prev]);
      setFile(null); // Tyhjennä vain jos onnistui
    } catch (err) {
      console.error("Upload failed", err);
      alert("Lataus epäonnistui: " + err.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="container py-4">
      <h1 className="text-center mb-4">📸 Hääkuvat</h1>

      <input
        aria-label="Valitse kuva tai video"
        type="file"
        accept="image/*,video/*"
        onChange={(e) => {
          console.log("Valittu tiedosto:", e.target.files[0]);
          setFile(e.target.files[0]);
        }}
        className="mb-2"
      />
      <button
        onClick={handleUpload}
        disabled={!file || uploading}
        className="bg-blue-500 text-black px-4 py-2 rounded disabled:opacity-50"
      >
        {uploading ? "Ladataan..." : "Lataa kuva/video"}
      </button>

      {photos.length === 0 ? (
        <div className="text-center text-muted">
          <p className="lead">Ei vielä yhtään kuvaa ladattuna.</p>
          <p>Ole ensimmäinen ja jaa muisto!</p>
        </div>
      ) : (
        <div className="w-100 mx-auto" style={{ maxWidth: "900px" }}>
          <Carousel fade interval={3000}>
            {photos.map((url, idx) => (
              <Carousel.Item key={idx}>
                {url.match(/\.(mp4|webm|ogg)$/i) ? (
                  <video
                    controls
                    className="d-block w-100"
                    style={{ maxHeight: "80vh", objectFit: "contain" }}
                  >
                    <source src={url} />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={url}
                    alt={`Kuva ${idx}`}
                    onError={() =>
                      console.error("Kuvan lataus epäonnistui:", url)
                    }
                    className="d-block w-100"
                    style={{ maxHeight: "80vh", objectFit: "contain" }}
                  />
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}

/* // WeddingPhotoCarousel.jsx (tallennus omalle palvelimelle)
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const API_BASE = "https://upload.villemajava.com"; // Vaihdettu domainiin

export default function WeddingPhotoCarousel() {
  const [file, setFile] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);

  // 🔁 Lataa olemassa olevat kuvat kun sivu avataan
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const res = await axios.get(`${API_BASE}/photos`);
        console.log("Haetut kuvat:", res.data);
        setPhotos(res.data);
      } catch (err) {
        console.error("Kuvien haku epäonnistui:", err);
      }
    };
    fetchPhotos();
  }, []);

  const handleUpload = async () => {
    if (!file) return;
    setUploading(true);
    const formData = new FormData();
    formData.append("media", file);

    try {
      const res = await axios.post(`${API_BASE}/upload`, formData, {
        timeout: 30000, // 30 sekuntia
      });
      const uploadedUrl = res.data.url;
      console.log("Tallennettu tiedoston URL:", uploadedUrl);
      setPhotos((prev) => [uploadedUrl, ...prev]);
      setFile(null); // Tyhjennä vain jos onnistui
    } catch (err) {
      console.error("Upload failed", err);
      alert("Lataus epäonnistui: " + err.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">📸 Hääkuvat</h1>

      <input
        type="file"
        accept="image/*,video/*"
        onChange={(e) => {
          console.log("Valittu tiedosto:", e.target.files[0]);
          setFile(e.target.files[0]);
        }}
        className="mb-2"
      />
      <button
        onClick={handleUpload}
        disabled={!file || uploading}
        className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
      >
        {uploading ? "Ladataan..." : "Lataa kuva/video"}
      </button>

      <div className="mt-4">
        {photos.length === 0 ? (
          <div className="text-gray-500 mt-4">
            <p className="text-lg">Ei vielä yhtään kuvaa ladattuna.</p>
            <p className="text-sm">Ole ensimmäinen ja jaa muisto!</p>
            <div className="mt-4">
              <span className="text-5xl">📷</span>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              loop={photos.length > 1}
              modules={[Autoplay]}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              className="demo-swiper-multiple"
            >
              {photos.map((url, idx) => (
                <SwiperSlide key={idx}>
                  {url.match(/\.mp4$|\.webm$|\.ogg$/) ? (
                    <video
                      controls
                      className="max-w-full object-contain rounded-lg shadow"
                    >
                      <source src={url} />
                    </video>
                  ) : (
                    <img
                      src={url}
                      alt={`Media ${idx}`}
                      onError={() =>
                        console.error("Kuvan lataus epäonnistui:", url)
                      }
                      className="max-w-full object-contain rounded-lg shadow"
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
}
 */
